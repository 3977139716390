<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content">
      <div class="top-content-text">
        <h1 class="top-content-text-title">CZ-TECH.RU</h1>
        <p class="top-content-text-description">
          Привлекайте клиентов с помощью cz-tech.ru <br>
          — платформа для самостоятельного <br>
          размещения таргетированной рекламы в <br>
          разных интернет источниках
        </p>
      </div>
      <div class="top-content-left">
        <img
          class="top-content-left-image"
          src="@/assets/images/Artboard-17.webp"
          alt="">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top {
  margin-top: get-vw(400px);
  @media (orientation: landscape){
    margin-top: get-vw(200px);
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    margin-left: auto;
    margin-right: auto;
    @media (orientation: landscape){
      width: 65%;
    }
    &-left {
      width: 100%;
      &-image {
        margin-left: get-vw(-55px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      margin-left: get-vw(50px);
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: get-vw(170px);
        color: #4244E6;
        text-shadow: 0 get-vw(10px) get-vw(10px) rgba(0, 0, 0, 0.25);
        padding-left: get-vw(45px);
        @media (orientation: landscape){
          font-size: get-vw(100px);
        }
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(65px);
        line-height: get-vw(90px);
        color: #000000;
        padding-left: get-vw(40px);
        margin-top: get-vw(70px);
        border-left: get-vw(10px) solid #4C4EF9;
        @media (orientation: landscape){
          font-size: get-vw(40px);
          line-height: get-vw(60px);
        }
      }
    }
  }
}
</style>
